import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bc6d9f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "html-preview" }
const _hoisted_2 = { class: "html-preview__content" }
const _hoisted_3 = { class: "html-preview__lines" }
const _hoisted_4 = { class: "html-preview__lines-in" }
const _hoisted_5 = { class: "html-preview__text" }
const _hoisted_6 = {
  key: 0,
  class: "html-preview__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor_buttons = _resolveComponent("editor-buttons")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(30, (i) => {
            return _createElementVNode("div", { key: i }, _toDisplayString(i), 1)
          }), 64))
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    (_ctx.showFooter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_editor_buttons, { buttons: _ctx.btnsList }, null, 8, ["buttons"])
        ]))
      : _createCommentVNode("", true)
  ]))
}