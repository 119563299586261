
import { defineComponent } from 'vue'
import EditorButtons from '@/components/shared/editor/EditorButtons.vue'
import { editorBtns } from '@/definitions/shared/editor/data'

export default defineComponent({
  components: {
    EditorButtons,
  },
  props: {
    showFooter: {
      type: Boolean,
    },
  },
  emits: ['click:templates'],
  setup(props, context) {
    const btnsList = [
      {
        ...editorBtns.templates,
        clickEvent: () => {
          context.emit('click:templates')
        },
      },
      editorBtns.divider,
      editorBtns.expand,
    ]

    return {
      btnsList,
    }
  },
})
